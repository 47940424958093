* {
  margin: 0;
  box-sizing: border-box;
}

#team {
  background-image: url("../images/meetings-bg.jpg");
}

.teamName {
  text-align: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: xx-large;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
}

.eventname {
  text-align: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: xx-large;
  color: white;
  font-weight: 700;
  padding-top: 10rem;
}

.maindiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.subdiv {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 40px 0;
}

.subdiv .card {
  position: relative;
  width: 300px;
  height: 400px;
  background-image: url("../images/service-item-bg.jpg");
  margin: 20px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
}

.subdiv .card .content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  opacity: 0.8;
  transition: 0.5s;
}
.subdiv .card:hover .content {
  opacity: 1;
  transform: translateY(-30px);
}
.subdiv .card .content .imgBx {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  border: 10px solid rgb(233, 233, 233);
}
.subdiv .card .content .imgBx img {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.subdiv .card .content .contentBx h3 {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  margin: 20px 0 10px;
  line-height: 1.1em;
}
.subdiv .card .content .contentBx h3 span {
  font-size: 15px;
  font-weight: 300;
  text-transform: initial;
}

.subdiv .card .sci {
  position: absolute;
  bottom: 30px;
  display: flex;
  left: 50px;
}

.subdiv .card .sci li {
  list-style: none;
  margin: 0 10px;
  transform: translateY(40px);
  transition: 0.5s;
  transition-delay: calc(0.1s * var(--i));
  opacity: 0;
  align-content: center;
}
.subdiv .card:hover .sci li {
  transform: translateY(0px);
  opacity: 1;
}
.subdiv .card .sci li a {
  color: #fff;
  font-size: 30px;
}
