#main {
  background-image: url("../images/meetings-bg.jpg");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding: 20px;
}

.magazine-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
}


.img-fluid {
  width: 100%;
  max-width: 18rem; /* Reduce image width */
  height: auto;
  margin: 0; /* Remove margin */
}

.section-header {
  margin-bottom: 60px;
  padding-bottom: 20px;
  padding-top: 50px;
  text-align: center;
  position: relative; /* Make it relative for the pseudo-element */
}

.section-header::before {
  content: "";
  position: absolute; /* Use absolute positioning to place it relative to the parent */
  width: 60px;
  height: 5px;
  background: #f82249;
  bottom: 20px; /* Position it below the text */
  left: 50%;
  transform: translateX(-50%); /* Center it horizontally */
}

.section-header h2 {
  font-size: 36px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  margin-bottom: 10px;
  color: #fff;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start; /* Align items at the top */
}

.col-md-5,
.col-md-6 {
  flex-basis: 45%;
  padding: 5px; /* Reduce padding */
}

.details h2 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 10px;
}

.details .social {
  margin-bottom: 15px;
}

.details .social a {
  background: #e9edfb;
  color: #112363;
  display: inline-block;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.details .social a:hover {
  background: #f82249;
  color: #fff;
}

.details p {
  color: #fff;
  font-size: 15px;
  margin-bottom: 10px;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .row {
    flex-direction: column;
    align-items: center;
  }

  .magazine-image,
  .col-md-6 {
    flex-basis: 100%;
    text-align: center;
  }

  .img-fluid {
    margin-top: 20px;
    width: 90%; /* Responsive width for smaller screens */
  }

  .details {
    margin-top: 20px;
  }
}
