/*
  Theme Name: TheEvent
  Theme URL: https://bootstrapmade.com/theevent-conference-event-bootstrap-template/
  Author: BootstrapMade.com
  License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #fff;
  color: #2f3138;
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
}

a {
  color: #f82249;
  transition: 0.5s;
}

a:hover,
a:active,
a:focus {
  color: #f8234a;
  outline: none;
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0 0 30px 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
  color: #0e1b4d;
}

.App {
  height: 100vh;
}

/* Prelaoder */

#preloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: visible;
  /* background: #fff url("../img/preloader.svg") no-repeat center center; */
}

/* Back to top button */

.back-to-top {
  position: fixed;
  display: none;
  background: #f82249;
  color: #fff;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50px;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s ease-in-out;
}

.back-to-top i {
  font-size: 24px;
  padding-top: 6px;
}

.back-to-top:focus {
  background: #e0072f;
  color: #fff;
  outline: none;
}

.back-to-top:hover {
  background: #e0072f;
  color: #fff;
}

/* Sections Header
--------------------------------*/

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/

/* Nav Menu Essentials */

/* Mobile Nav Toggle */

#mobile-nav-toggle {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  margin: 15px 15px 0 0;
  border: 0;
  background: none;
  font-size: 24px;
  display: none;
  transition: all 0.4s;
  outline: none;
  cursor: pointer;
}

#mobile-nav-toggle i {
  color: #fff;
}

/* Mobile Nav Styling */

#mobile-nav {
  position: fixed;
  top: 0;
  padding-top: 18px;
  bottom: 0;
  z-index: 998;
  background: rgba(6, 12, 34, 0.9);
  left: -260px;
  width: 260px;
  overflow-y: auto;
  transition: 0.4s;
}

#mobile-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#mobile-nav ul li {
  position: relative;
}

#mobile-nav ul li a {
  color: #fff;
  font-size: 17px;
  overflow: hidden;
  padding: 10px 22px 10px 15px;
  position: relative;
  text-decoration: none;
  width: 100%;
  display: block;
  outline: none;
}

#mobile-nav ul li a:hover {
  color: #f82249;
}

#mobile-nav ul li li {
  padding-left: 30px;
}

#mobile-nav ul .menu-has-children i {
  position: absolute;
  right: 0;
  z-index: 99;
  padding: 15px;
  cursor: pointer;
  color: #fff;
}

#mobile-nav ul .menu-has-children i.fa-chevron-up {
  color: #f82249;
}

#mobile-nav ul .menu-item-active {
  color: #f82249;
}

#mobile-body-overly {
  width: 100%;
  height: 100%;
  z-index: 997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(6, 12, 34, 0.8);
  display: none;
}

/* Mobile Nav body classes */

body.mobile-nav-active {
  overflow: hidden;
}

body.mobile-nav-active #mobile-nav {
  left: 0;
}

body.mobile-nav-active #mobile-nav-toggle {
  color: #fff;
}

/*--------------------------------------------------------------
# Intro Section
--------------------------------------------------------------*/
#intro {
  width: 100%;
  height: 100vh;
  /* Full height of the viewport */
  background-size: cover;
  background-position: center center;
  /* Ensure the image is centered */
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  background-image: url("./components/images/Committee.jpg");
}

@media (max-width: 768px) {
  #intro {
    min-height: 100vh;
    /* Allow the height to adjust based on the content */
    background-size: cover;
    /* Ensure the image is fully visible */
    background-position: center top;
    /* Adjust image position for mobile */
    padding-bottom: 20px;
    /* Add some padding to avoid content being too close to the edges */
  }
}

#intro:before {
  content: "";
  background: rgba(6, 12, 34, 0.8);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#intro .intro-container {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 90px;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  padding: 0 15px;
}

#intro h1 {
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-size: 38px;
  font-weight: 600;
  text-transform: uppercase;
}

#intro h1 span {
  color: #f82249;
}

#intro p {
  color: #ebebeb;
  font-weight: 700;
  font-size: 28px;
}

#intro .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(#f82249 50%, rgba(101, 111, 150, 0.15) 52%);
  border-radius: 50%;
  display: block;
  position: relative;
  overflow: hidden;
}

#intro .play-btn::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-40%) translateY(-50%);
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

#intro .play-btn:before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate-btn 2s;
  animation: pulsate-btn 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 2px solid rgba(163, 163, 163, 0.4);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

#intro .play-btn:hover::after {
  border-left: 15px solid #f82249;
  -webkit-transform: scale(20);
  transform: scale(20);
}

#intro .play-btn:hover::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-40%) translateY(-50%);
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  -webkit-animation: none;
  animation: none;
  border-radius: 0;
}

#intro .about-btn {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 2px solid #f82249;
}

#intro .about-btn:hover {
  background: #f82249;
  color: #fff;
}

@-webkit-keyframes pulsate-btn {
  0% {
    -webkit-transform: scale(0.6, 0.6);
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes pulsate-btn {
  0% {
    -webkit-transform: scale(0.6, 0.6);
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# About Section
--------------------------------------------------------------*/

#about {
  /* background: url("../img/about-bg.jpg"); */
  background-size: cover;
  overflow: hidden;
  position: relative;
  color: #fff;
  padding: 60px 0 40px 0;
}

#about:before {
  content: "";
  background: rgba(13, 20, 41, 0.8);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#about h2 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
}

#about h3 {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #fff;
}

#about p {
  font-size: 14px;
  margin-bottom: 20px;
  color: #fff;
}

/*--------------------------------------------------------------
# Speakers Section
--------------------------------------------------------------*/

.new-events {
  background-color: rgb(184, 3, 3);
  border-radius: 10px;
  width: 50px;
  right: 10px;
  top: 10px;
  text-align: center;
  text-decoration: underline;
  color: white;
  position: absolute;
  animation: animate 1.5s linear infinite;
}

@keyframes animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Schedule Section
--------------------------------------------------------------*/

#schedule {
  padding: 60px 0 60px 0;
}

#schedule .nav-tabs {
  text-align: center;
  margin: auto;
  display: block;
  border-bottom: 0;
  margin-bottom: 30px;
}

#schedule .nav-tabs li {
  display: inline-block;
  margin-bottom: 0;
}

#schedule .nav-tabs a {
  border: none;
  border-radius: 50px;
  font-weight: 600;
  background-color: #0e1b4d;
  color: #fff;
  padding: 10px 100px;
}

#schedule .nav-tabs a.active {
  background-color: #f82249;
  color: #fff;
}

#schedule .sub-heading {
  text-align: center;
  font-size: 18px;
  font-style: italic;
  margin: 0 auto 30px auto;
}

#schedule .tab-pane {
  transition: ease-in-out 0.2s;
}

#schedule .schedule-item {
  border-bottom: 1px solid #cad4f6;
  padding-top: 15px;
  padding-bottom: 15px;
  transition: background-color ease-in-out 0.3s;
}

#schedule .schedule-item:hover {
  background-color: #fff;
}

#schedule .schedule-item time {
  padding-bottom: 5px;
  display: inline-block;
}

#schedule .schedule-item .speaker {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  float: left;
  margin: 0 10px 10px 0;
}

#schedule .schedule-item .speaker img {
  height: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-left: 50%;
  transition: all ease-in-out 0.3s;
}

#schedule .schedule-item h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}

#schedule .schedule-item h4 span {
  font-style: italic;
  color: #19328e;
  font-weight: normal;
  font-size: 16px;
}

#schedule .schedule-item p {
  font-style: italic;
  color: #152b79;
  margin-bottom: 0;
}

.calendar {
  width: 60%;
  height: 60%;
  margin-right: 400px;
  margin-bottom: 15px;
}

.calendar-toright {
  width: 60%;
  height: 60%;
  margin-left: 400px;
  margin-bottom: 15px;
}

@media (max-width: 500px) {
  .calendar {
    width: 60%;
    height: 60%;
    margin-right: 100px;
    margin-bottom: 15px;
  }

  .calendar-toright {
    width: 60%;
    height: 60%;
    margin-left: 100px;
    margin-bottom: 15px;
  }
}

/*--------------------------------------------------------------
# Venue Section
--------------------------------------------------------------*/

#venue {
  padding: 60px 0;
}

#venue .container-fluid {
  margin-bottom: 3px;
}

#venue .venue-map iframe {
  width: 100%;
  height: 100%;
  min-height: 300px;
}

#venue .venue-info {
  /* background: url("../img/venue-info-bg.jpg") top center no-repeat; */
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

#venue .venue-info:before {
  content: "";
  background: rgba(13, 20, 41, 0.8);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#venue .venue-info h3 {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
}

#venue .venue-info p {
  color: #fff;
  margin-bottom: 0;
}

#venue .venue-gallery-container {
  padding-right: 12px;
}

#venue .venue-gallery {
  overflow: hidden;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
}

#venue .venue-gallery img {
  transition: all ease-in-out 0.4s;
}

#venue .venue-gallery:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Hotels Section
--------------------------------------------------------------*/

#hotels {
  padding: 60px 0;
}

#hotels .hotel {
  border: 1px solid #e0e5fa;
  background: #fff;
  margin-bottom: 30px;
}

#hotels .hotel:hover .hotel-img img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

#hotels .hotel-img {
  overflow: hidden;
  margin-bottom: 15px;
}

#hotels .hotel-img img {
  transition: 0.3s ease-in-out;
}

#hotels h3 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 5px;
  padding: 0 20px;
}

#hotels a {
  color: #152b79;
}

#hotels a:hover {
  color: #f82249;
}

#hotels .stars {
  padding: 0 20px;
  margin-bottom: 5px;
}

#hotels .stars i {
  color: #ffc31d;
}

#hotels p {
  padding: 0 20px;
  margin-bottom: 20px;
  color: #060c22;
  font-style: italic;
  font-size: 15px;
}

/*--------------------------------------------------------------
# Gallery Section
--------------------------------------------------------------*/

#gallery {
  padding: 60px;
  overflow: hidden;
}

#gallery .owl-nav,
#gallery .owl-dots {
  margin-top: 25px;
  text-align: center;
}

#gallery .owl-item {
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
}

#gallery .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd;
}

#gallery .owl-dot.active {
  background-color: #f82249;
}

#gallery .gallery-carousel .owl-stage-outer {
  overflow: visible;
}

#gallery .gallery-carousel .center {
  border: 6px solid #f82249;
  margin: -10px;
  box-sizing: content-box;
  padding: 4px;
  background: #fff;
  z-index: 1;
}

/*--------------------------------------------------------------
# Sponsors Section
--------------------------------------------------------------*/

#sponsors {
  padding: 60px 0;
}

#sponsors .sponsors-wrap {
  border-top: 1px solid #e0e5fa;
  border-left: 1px solid #e0e5fa;
  margin-bottom: 30px;
}

#sponsors .sponsor-logo {
  padding: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 1px solid #e0e5fa;
  border-bottom: 1px solid #e0e5fa;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.5);
  height: 160px;
}

#sponsors .sponsor-logo:hover img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

#sponsors img {
  transition: all 0.4s ease-in-out;
}

/*--------------------------------------------------------------
# F.A.Q Section
--------------------------------------------------------------*/

#faq {
  padding: 60px 0;
}

#faq #faq-list {
  padding: 0;
  list-style: none;
}

#faq #faq-list li {
  border-bottom: 1px solid #ddd;
}

#faq #faq-list a {
  padding: 18px 0;
  display: block;
  position: relative;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  padding-right: 20px;
}

#faq #faq-list i {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 16px;
}

#faq #faq-list p {
  margin-bottom: 20px;
}

#faq #faq-list a.collapse {
  color: #f82249;
}

#faq #faq-list a.collapsed {
  color: #000;
}

#faq #faq-list a.collapsed i::before {
  content: "\f055" !important;
}

/*--------------------------------------------------------------
# Subscribe Section
--------------------------------------------------------------*/

#subscribe {
  padding: 60px;
  /* background: url(../img/subscribe-bg.jpg) center center no-repeat; */
  background-size: cover;
  overflow: hidden;
  position: relative;
}

#subscribe:before {
  content: "";
  background: rgba(6, 12, 34, 0.6);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#subscribe .section-header h2,
#subscribe p {
  color: #fff;
}

#subscribe input {
  background: #fff;
  color: #060c22;
  border: 0;
  outline: none;
  margin: 0;
  padding: 9px 20px;
  border-radius: 50px;
  font-size: 14px;
}

#subscribe button {
  border: 0;
  padding: 9px 25px;
  cursor: pointer;
  background: #f82249;
  color: #fff;
  transition: all 0.3s ease;
  outline: none;
  font-size: 14px;
  border-radius: 50px;
}

#subscribe button:hover {
  background: #e0072f;
}

/*--------------------------------------------------------------
# Buy Tickets Section
--------------------------------------------------------------*/

#buy-tickets {
  padding: 60px 0;
}

#buy-tickets .card {
  border: none;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 10px 25px 0 rgba(6, 12, 34, 0.1);
}

#buy-tickets .card:hover {
  box-shadow: 0 10px 35px 0 rgba(6, 12, 34, 0.2);
}

#buy-tickets .card hr {
  margin: 25px 0;
}

#buy-tickets .card .card-title {
  margin: 10px 0;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: bold;
}

#buy-tickets .card .card-price {
  font-size: 48px;
  margin: 0;
}

#buy-tickets .card ul li {
  margin-bottom: 20px;
}

#buy-tickets .card .text-muted {
  opacity: 0.7;
}

#buy-tickets .card .btn {
  font-size: 15px;
  border-radius: 50px;
  padding: 10px 40px;
  transition: all 0.2s;
  background-color: #f82249;
  border: 0;
  color: #fff;
}

#buy-tickets .card .btn:hover {
  background-color: #e0072f;
}

#buy-tickets #buy-ticket-modal input,
#buy-tickets #buy-ticket-modal select {
  border-radius: 0;
}

#buy-tickets #buy-ticket-modal .btn {
  font-size: 15px;
  border-radius: 50px;
  padding: 10px 40px;
  transition: all 0.2s;
  background-color: #f82249;
  border: 0;
  color: #fff;
}

#buy-tickets #buy-ticket-modal .btn:hover {
  background-color: #e0072f;
}

/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

#footer {
  background: #101522;
  padding: 0 0 25px 0;
  color: #eee;
  font-size: 14px;
}

#footer .footer-top {
  background: #040919;
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 26px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  color: #fff;
}

#footer .footer-top .footer-info img {
  height: 40px;
  margin-bottom: 10px;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
  color: #fff;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #222636;
  color: #eee;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #f82249;
  color: #fff;
}

#footer .footer-top h4 {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
  border-bottom: 2px solid #f82249;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 5px;
  color: #f82249;
  font-size: 18px;
}

#footer .footer-top .footer-links ul li {
  border-bottom: 1px solid #262c44;
  padding: 10px 0;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #eee;
}

#footer .footer-top .footer-links ul a:hover {
  color: #f82249;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact p {
  line-height: 26px;
}

#footer .footer-top .footer-newsletter {
  margin-bottom: 30px;
}

#footer .footer-top .footer-newsletter input[type="email"] {
  border: 0;
  padding: 6px 8px;
  width: 65%;
}

#footer .footer-top .footer-newsletter input[type="submit"] {
  background: #f82249;
  border: 0;
  width: 35%;
  padding: 6px 0;
  text-align: center;
  color: #fff;
  transition: 0.3s;
  cursor: pointer;
}

#footer .footer-top .footer-newsletter input[type="submit"]:hover {
  background: #e0072f;
}

#footer .copyright {
  text-align: center;
  padding-top: 30px;
}

#footer .credits {
  text-align: center;
  font-size: 13px;
  color: #ddd;
}

/*--------------------------------------------------------------
# Responsive Media Queries
--------------------------------------------------------------*/

@media (min-width: 767px) {
  #subscribe input {
    min-width: 400px;
  }
}

@media (min-width: 768px) {

  #contact .contact-address,
  #contact .contact-phone,
  #contact .contact-email {
    padding: 20px 0;
  }

  #contact .contact-phone {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
}

@media (min-width: 991px) {
  #schedule .sub-heading {
    width: 75%;
  }
}

@media (min-width: 1024px) {
  #intro {
    background-attachment: fixed;
  }

  #about {
    background-attachment: fixed;
  }

  #subscribe {
    background-attachment: fixed;
  }
}

@media (max-width: 1199px) {
  #header .container {
    max-width: 100%;
  }

  .nav-menu a {
    padding: 8px 4px;
  }
}

@media (max-width: 991px) {
  #header {
    background: rgba(6, 12, 34, 0.98);
    height: 70px;
    padding: 15px 0;
    transition: all 0.5s;
  }

  #nav-menu-container {
    display: inline;
  }

  #mobile-nav-toggle {
    display: inline;
  }

  #intro .intro-container {
    top: 70px;
  }

  #intro h1 {
    font-size: 34px;
  }

  #intro p {
    font-size: 16px;
  }

  #schedule .nav-tabs a {
    padding: 8px 60px;
  }
}

@media (max-width: 768px) {
  .back-to-top {
    bottom: 15px;
  }

  #faq #faq-list a {
    font-size: 18px;
  }

  #faq #faq-list i {
    top: 13px;
  }
}

@media (max-width: 767px) {
  #schedule .nav-tabs a {
    padding: 8px 50px;
  }
}

@media (max-width: 574px) {
  #venue .venue-info h3 {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  #schedule .nav-tabs a {
    padding: 8px 30px;
  }
}

@media (max-width: 460px) {
  #subscribe button {
    margin-top: 10px;
  }
}