#contact {
  background-image: url("../images/meetings-bg.jpg");
  height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-section-header {
  margin-bottom: 60px;
  margin-top: 100px;
  position: relative;
  padding-bottom: 20px;
  padding-top: 50px;
}

.contact-section-header::before {
  content: "";
  position: absolute;
  display: block;
  width: 60px;
  height: 5px;
  background: #f82249;
  top: 6rem;
  left: calc(50% - 25px);
}

.contact-section-header h2 {
  font-size: 36px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  margin-bottom: 10px;
  color: #fff;
}

#contact .contact-info {
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
}

#contact .contact-info i {
  font-size: 48px;
  display: inline-block;
  margin-bottom: 10px;
  color: #f82249;
}

#contact .contact-info address,
#contact .contact-info p {
  margin-bottom: 0;
  color: #112363;
}

#contact .contact-info h3 {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: bold;
  text-transform: uppercase;
  color: #112363;
}

#contact .contact-info a {
  color: #4869df;
}

#contact .contact-info a:hover {
  color: #f82249;
}

#contact .contact-address,
#contact .contact-phone,
#contact .contact-email {
  margin-bottom: 20px;
}

#contact .contact-email {
  justify-self: center;
}

.form {
  max-width: 1110px;
}
#contact .form #sendmessage {
  color: #f82249;
  border: 1px solid #f82249;
  display: none;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #errormessage {
  color: red;
  display: none;
  border: 1px solid red;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #sendmessage.show,
#contact .form #errormessage.show,
#contact .form .show {
  display: block;
}

#contact .form .validation {
  color: red;
  display: none;
  margin: 0 0 20px;
  font-weight: 400;
  font-size: 13px;
}

#contact .form input,
#contact .form textarea {
  padding: 10px 14px;
  border-radius: 0;
  box-shadow: none;
  font-size: 15px;
}

#contact .form button[type="submit"] {
  background: #f82249;
  border: 0;
  padding: 10px 40px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
  cursor: pointer;
}

#contact .form button[type="submit"]:hover {
  background: #e0072f;
}

.form-row {
  display: flex;
  gap: 0.313rem; /* Space between name and email fields */
}

.form-group {
  flex: 1; /* Make the form fields expand to fill the available space */
}

.form-group.col-md-6 {
  width: 500px; /* Ensure both fields occupy 50% of the row */
  padding: 0;
  margin: 1rem 0.313rem; /* Optional */
}

* {
  box-sizing: border-box;
}
